import { useDispatch, useSelector } from 'react-redux';

import { addToFavorites, removeFromFavorites } from '@/redux/modules/favorites';
import { getUserFavorites } from '@/redux/selectors/favorites';
import {
  trackListingFavoritedEvent,
  trackListingUnfavoritedEvent,
} from '@/services/analytics/listings';
import { EListingSource } from '@/services/analytics/listings/types';
import { EAlgoSlugVariationKey } from '@/services/experiments';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

function useFavorites({
  eventSource,
  page = 1,
  carouselAlgoSlug,
}: {
  eventSource?: EListingSource;
  page?: number;
  carouselAlgoSlug?: EAlgoSlugVariationKey;
}) {
  const dispatch = useDispatch();
  const favorites = useSelector(getUserFavorites);

  const addFavorite = (rental: IRentalTile, index: number) => {
    const id = rental?.rental_id || rental?.id;

    if (id) {
      dispatch(addToFavorites(id));
      if (eventSource) {
        trackListingFavoritedEvent(rental, index + 1, eventSource, page, carouselAlgoSlug);
      }
    }
  };

  const removeFavorite = (rental: IRentalTile, index: number) => {
    const id = rental?.rental_id || rental?.id;

    if (id) {
      dispatch(removeFromFavorites(id));
      if (eventSource) {
        trackListingUnfavoritedEvent(rental, index + 1, eventSource, page, carouselAlgoSlug);
      }
    }
  };

  return { favorites, addFavorite, removeFavorite };
}

export default useFavorites;
