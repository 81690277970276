import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getRentals } from '@/actions/rentals';
import Heading from '@/components/switchback/Heading/Heading';
import { getUserCurrency } from '@/redux/selectors/currency';
import { getRentalsMeta } from '@/redux/selectors/listing/rentalsFilters';
import { getSimilarRentalsTiles } from '@/redux/selectors/listing/similarRentals';
import { EListingSource } from '@/services/analytics/listings/types';
import { EAlgoSlugVariationKey } from '@/services/experiments';

import css from './DynamicRentalsCarousel.module.css';
import { RentalsCarousel } from './RentalsCarousel';

export const DynamicRentalsCarousel = ({ eventSource }: { eventSource: EListingSource }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userCurrency = useSelector(getUserCurrency);
  const rentalsList = useSelector(getSimilarRentalsTiles);
  const carouselHeading = useSelector(getRentalsMeta)?.carousel_name;
  const getPageRentals = React.useCallback(filters => dispatch(getRentals(filters)), [dispatch]);

  // Fetch rentals on mount, or when the currency changes
  React.useEffect(() => {
    getPageRentals({ count: 9, slug: true });
  }, [getPageRentals, userCurrency]);

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <div className="flex items-center justify-between w-full mb-8 text-gray-900">
          <Heading level={2} className={css.title}>
            {carouselHeading || intl.formatMessage({ defaultMessage: 'Available this weekend.' })}
          </Heading>
        </div>
        <RentalsCarousel
          rentalsList={rentalsList}
          eventSource={eventSource}
          carouselAlgoSlug={EAlgoSlugVariationKey.weekender}
        />
      </div>
    </div>
  );
};
