import Image from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';

import Button, { ButtonSize, ButtonVariants } from '@/components/switchback/Button/Button';
import Heading from '@/components/switchback/Heading/Heading';
import { IBlogPost } from '@/services/types/blog/post.types';
import { decodeString } from '@/utility/stripHtml';

import css from './NotesFromOurJournal.module.css';

interface Props {
  posts: IBlogPost[];
}

export const NotesFromOurJournal: React.FC<Props> = ({ posts }) => {
  const intl = useIntl();

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <Heading level={2} className={css.title}>
          {intl.formatMessage({ defaultMessage: 'Notes from our journal' })}
        </Heading>

        <div className={css.articles}>
          {posts.slice(0, 1).map(post => (
            <a href={post.url} className={css.mainArticle} key={post.id}>
              <figure>
                {post.images?.full?.source_url && (
                  <Image
                    src={post.images.full.source_url}
                    alt={post.title || 'Outdoorsy Blog Post Image'}
                    layout="fill"
                    objectFit="cover"
                  />
                )}
              </figure>
              {!!post.tagName && <span className={css.label}>{decodeString(post.tagName)}</span>}
              {!!post.title && <span className={css.description}>{decodeString(post.title)}</span>}
            </a>
          ))}

          <div className={css.smallArticles}>
            {posts.slice(1, 3).map(post => (
              <a href={post.url} className={css.article} key={post.id}>
                <figure>
                  {post.images?.full?.source_url && (
                    <Image
                      src={post.images?.full.source_url}
                      alt={post.title || 'Outdoorsy Secondary Blog Post Article'}
                      layout="fill"
                      objectFit="cover"
                    />
                  )}
                </figure>
                <span>
                  {!!post.tagName && (
                    <span className={css.label}>{decodeString(post.tagName)}</span>
                  )}
                  {!!post.title && (
                    <Heading level={6} className={css.description}>
                      {decodeString(post.title)}
                    </Heading>
                  )}
                </span>
              </a>
            ))}
          </div>
        </div>

        <div className={css.readMore}>
          <Button href="/blog" size={ButtonSize.xsmall} variant={ButtonVariants.primaryOutlined}>
            {intl.formatMessage({ defaultMessage: 'Read our journal' })}
          </Button>
        </div>
      </div>
    </div>
  );
};
