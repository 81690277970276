import React, { MouseEvent } from 'react';
import Slider from 'react-slick';

import Button, { ButtonShape, ButtonVariants } from '../Button/Button';
import Icon from '../Icon/Icon';
import { ICONS } from '../Icon/Icon.constants';
import css from './Arrow.module.css';

export enum ArrowDirections {
  right = 'right',
  left = 'left',
}

export enum ArrowVariants {
  inside = 'inside',
  outside = 'outside',
  half = 'half',
  simple = 'simple',
}

export enum ArrowStates {
  hidden = 'hidden',
}

interface IArrowProps {
  direction: ArrowDirections;
  onClick?: () => void;
  state?: ArrowStates;
  variant?: ArrowVariants;
  hasShadow?: boolean;
  customClassName: string;
  componentName: string;
  label?: string;
}

const Arrow = React.forwardRef<Slider | null, IArrowProps>(function Arrow(
  {
    direction,
    state,
    onClick,
    variant,
    hasShadow = true,
    componentName = 'carousel',
    customClassName,
    label,
  },
  ref,
) {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (ref && typeof ref !== 'function') {
      if (direction === ArrowDirections.right) ref.current?.slickNext();
      else ref.current?.slickPrev();
      return;
    }
    onClick && onClick();
  };
  return (
    <div
      data-shadow={hasShadow}
      onClick={handleClick}
      data-variant={variant}
      data-direction={direction}
      data-state={state}
      data-testid={
        direction === ArrowDirections.right
          ? `${componentName}-scroll-forward`
          : `${componentName}-scroll-back`
      }
      className={`${customClassName} ${css.arrow} cursor-pointer arrow p-8 absolute transition-all ease-in-out duration-200 top-center transform -translate-y-1/2 z-10`}>
      {variant === ArrowVariants.simple ? (
        <div className="transform rotate-180">
          <Icon name={ICONS.CARET_LARGE} />
        </div>
      ) : (
        <Button
          aria-label={label}
          shape={ButtonShape.circle}
          variant={ButtonVariants.whiteContained}>
          <Icon name={ICONS.ARROW_RIGHT} />
        </Button>
      )}
    </div>
  );
});

export default Arrow;
